.page {
  background-color: #d8d8d8;
}

#appTitle {
  font-weight: bolder;
}

#appTitle a {
  text-decoration: none;
  color: #ffffff;
}

#userStatus a {
  text-decoration: none;
  color: #ffffff;
}

header, footer {
  background-color: blueviolet;
  color: white;
  padding: 15px;
}

#app {
  margin-top: 20px;
}

textarea {
  border-radius: 10px;
}

label {
  font-size: 20px;
}
